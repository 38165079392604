export const TrashItemTypes = {
  NOT_RECYLABLE: 'not_recyclable',
  PLASTIC: 'plastic',
  GLASS: 'glass',
  PAPER: 'paper',
  COMPOSTING: 'composting',
  ELECTRONICS: 'electronics',
  CLOSET: 'closet',
  FRIDGE: 'fridge',
  FREEZER: 'freezer'
}
