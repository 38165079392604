import CustomScroll from 'react-custom-scroll';
import { Link } from "react-router-dom";
import { ContentScreenFooter } from '../../components/common/ContentScreenFooter';
import { ContentScreenHeader } from '../../components/common/ContentScreenHeader';
import { CampaignSiteUrl, FOOD_TRASHES_GAME_PREFIX } from '../../data';

function IntroScreen() {
  return (
    <div className={`ContentScreen ${FOOD_TRASHES_GAME_PREFIX}`}>
      <div className="ScreenWrap">
        <ContentScreenHeader showHomeButton={true} />
        <div className="ContentScreenContent">
          <div className="ContentScreenContentLeft" style={{ maxWidth: '990px', margin: '0 auto' }}>
            <div className="ContentScreenContentHeader">Игра за хранителни отпадъци</div>
            <div className="ContentScreenContentContent">
              <CustomScroll heightRelativeToParent="100%">
                <p>
                Случвало ли ви се е да изхвърляте храна?
                </p>
                <p>
                Знаете ли, че 30 % от храната в света се изхвърля. И едновременно с това около 690 милиона души в света гладуват.
                </p>
                <p>
                Какво се случва с храната, която изхвърляме?
                </p>
                <p>
                Отива в сметището и там, докато се разлага, отделя газове, вредни за планетата. Колко енергия, суровини и време е изразходвано, за да стигне храната от фермата до нас?
                </p>
                <p>
                Когато изхвърляме храна, ние изхвърляме и усилията, енергията, средствата и суровините, вложени в производството й.
                </p>
                <p>
                Станете спасители на храна!
                </p>
              </CustomScroll>
            </div>
            <div className="ContentScreenContentButtons">
              <div className="ContentScreenContentButtonsLeft">
                <Link to="game" className="AppButtonDark right">Започни играта</Link>
              </div>
              <div className="ContentScreenContentButtonsRight">
                <a href={CampaignSiteUrl} className="AppButtonLight" target="_blank">Към сайта на кампанията</a>
                <Link to="terms" className="AppButtonLight">Обща информация</Link>
              </div>
            </div>
          </div>
        </div>
        <ContentScreenFooter showButtons={false} />
      </div>
    </div>
  );
}

export default IntroScreen;
