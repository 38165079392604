import { memo, useCallback, useState } from 'react'
import { useDrop } from 'react-dnd'

export const RecycleBin = memo(function Dustbin({
  accept,
  lastDroppedItem,
  onDrop,
  type
}) {
  const [justDropped, setJustDropped] = useState(false);
  const [isDropSuccess, setIsDropSuccess] = useState(false);

  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: (item) => {
      const isCorrect = type == item.type;
      dropTest(isCorrect);
      onDrop(item, isCorrect);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const dropTest = useCallback((isSuccessful) => {
    setIsDropSuccess(isSuccessful);
    setJustDropped(true);
    setTimeout(() => {
      setJustDropped(false);
    }, 1500);
  },[]);

  return (
    <div className={`RecycleBin RecycleBin${type}`} ref={drop} data-testid="dustbin">
      {
        justDropped ?
        (
          <div className={`RecycleBin${isDropSuccess == true ? 'Success' : 'Error'}`}></div>
        ) : null
      }
    </div>
  )
})
