import CustomScroll from 'react-custom-scroll';
import { Link } from "react-router-dom";
import { ContentScreenFooter } from '../../components/common/ContentScreenFooter';
import { ContentScreenHeader } from '../../components/common/ContentScreenHeader';
import { CampaignSiteUrl, ASSETS_STORAGE_PRFIX_PREFIX } from '../../data/Constants';

function TermsScreen() {
  const backRoutePath = "/" + window.location.pathname.replace(ASSETS_STORAGE_PRFIX_PREFIX, "").replace("terms", "");

  return (
    <div className="ContentScreen">
      <div className="ScreenWrap">
        <ContentScreenHeader />
        <div className="ContentScreenContent">
          <div className="ContentScreenContentFull">
            <div className="ContentScreenContentHeader">Обща информация</div>
            <div className="ContentScreenContentContent">
              <CustomScroll heightRelativeToParent="100%">
                <p>
                Трябва да осмислим, че рециклирането е важно за здравето на нашата планета, защото:
                </p>
                <p className="ContentScreenContentContentTitle">
                Опазва природата
                </p>
                <p>
                Рециклирането на хартия и дърво спасява гори.<br/>
                Рециклирането на пластмаса означава създаване на по-малко нова пластмаса, а това  спестява добив на изкопаеми горива за производството и намалява количеството на тези бавно разграждащи се отпадъци.<br/>
                Рециклирането на стъкло намалява използване на пясък, а днес някои видове пясък вече намаляха.
                </p>
                <p className="ContentScreenContentContentTitle">
                Опазва екосистемите и дивата природа
                </p>
                <p>
                Рециклирането намалява необходимостта от добив на нови суровини от Земята и свързаните с тях вредните въздействия върху природата: изсечени гори, отклонени реки, наранени или разселени диви животни, замърсени води, почви и въздух. Разбира се, ако нашите пластмасови отпадъци не бъдат предавани за рециклиране, те могат да се озоват в океаните, моретата, реките, превръщайки се в голям проблем за живите организми в тези местообитания и за цялото човечество.
                </p>
                <p className="ContentScreenContentContentTitle">
                Спестява енергия и намалява въглеродните емисии
                </p>
                <p>
                Чрез рециклирането се спестява енергия, а това допринася за намаляване на емисиите на въглероден диоксид и други парникови газове в атмосферата.
                Производството на продукти от рециклирани материали изисква по-малко енергия, отколкото от нови суровини.<br/>
                Например: производството на нов алуминий от стари продукти (включително рециклирани кутии и фолио) използва 95% по-малко енергия, отколкото традиционното му производството.<br/>
                Производството на хартия от пулпирана рециклирана хартия използва 40% по-малко енергия, отколкото производството й от необработени дървесни влакна.
                </p>
                <p className="ContentScreenContentContentTitle">
                По-евтино е от събирането и извозването на отпадъци към депата
                </p>
                <p>
                Колкото повече отпадъци за рециклиране предаваме, толкова повече пари се спестяват на общинските бюджети, което е добре за домакинствата, бизнеса и местните обществени услуги.<br/>
                Рециклирането на хранителни отпадъци и зелени отпадъци също е страхотна идея, защото се произвежда ценен компост – естествен тор, който може да използваме за подхранване на растенията, които отглеждаме.
                </p>
              </CustomScroll>
            </div>
            <div className="ContentScreenContentButtons">
              <div className="ContentScreenContentButtonsLeft">
                <Link to={backRoutePath} className="AppButtonDark left">Назад</Link>
                <a href={CampaignSiteUrl} className="AppButtonLight" target="_blank">Към сайта на кампанията</a>
              </div>
            </div>
          </div>
        </div>
        <ContentScreenFooter showButtons={false} />
      </div>
    </div>
  );
}

export default TermsScreen;
