import { useState } from "react";
import { Link } from "react-router-dom";
import TheKidsAudio from '../../assets/audio/TheKids.mp3';
import useGameMusics from "../../hooks/useGameMusics";
import { AlertModal } from "../../components/AlertModal";
import QuestionsGame from "../../components/questions-game/QuestionsGame";
import { TrashManagementQuestions } from '../../data';

function GameScreen(props) {
  const [screenDimensions, setScreenDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });
  const [alertModalDisplayed, setAlertModalDisplayed] = useState(true);

  const audioMuted = props.audioMuted;
  const setAudioMuted = props.setAudioMuted;
  const gameMusics = useGameMusics(TheKidsAudio, setAudioMuted);

  return (
    <>
      <div className={`GameCanvasScreen GameCanvasScreenTrashesManagement`}>
        <div className="GameCanvasScreen-TrashesManagement"></div>
        <div className="GameCanvasScreenHeader">
          <div className="GameCanvasScreenHeaderRow">
            <div className="GameCanvasScreenHeaderLeftButtons">
              <Link to="/trash-management" className="GoToHomeButton"></Link>
            </div>
            <div className="GameCanvasScreenPointsContainer">
              <div className="GameCanvasScreenExplain">
                Избери зона за почистване
              </div>
            </div>
            <div className="GameCanvasScreenHeaderRightButtons">
              <div className={`AudioButton ${audioMuted ? 'muted' : 'playing'}`} onClick={() => { gameMusics.toggleAudio(audioMuted) }}></div>
            </div>
          </div>
        </div>
        <QuestionsGame
          gameMusics={gameMusics}
          questionHideInterval={1000}
          hasManualSelection={true}
          questions={TrashManagementQuestions}
          successTitle="Поздравления! Ти Успя."
          errorTitle="Опитай отново!"
          successMessage="<p style='font-size: 14px; margin-top: 0;'>Всяка година хората изхвърлят милиони тонове отпадъци. И въпреки мерките за рециклиране и преработка на тези отпадъци те все още са сериозна заплаха за природата и собственото ни здраве.</p>
<p style='font-size: 14px;'>Затова е важно да намерим начин да намалим това количество. Вече разбрахте, че рециклирането и другото оползотворяване на отпадъците са едва трета и четвърта стъпка в тяхното управление. Много по-важно, а и по-лесно е да намалим количеството на отпадъците, които изхвърляме като пазаруваме разумно и избягваме опаковки за еднократна употреба.</p>
<p style='font-size: 14px;'>Важно е, когато пазаруваме добре да преценим какво точно ни е нужно и в какво количество, за да не се налага да изхвъляме ненужните остатъци. Някои от вещите, които се ползват рядко (декорация за тържества, индтрументи и др.) бихме могли да вземем или дадем назаем.</p>
<p style='font-size: 14px;'>Не са нужни усилия, за да използваш платнена торбичка, когато пазаруваш вместо всеки път да получаваш найлонова торбичка, която след това да изхвърлиш. Защо да купуваш пластмасово шише с вода за еднократна употреба всеки ден след като можеш да използваш такова за многократна употреба и да си носиш вода от вкъщи? В световния океан вече има цели острови, образувани пластмасови отпадъци!</p>
<p style='font-size: 14px;'>Не е ли по-добре да превърнем обелките от плодове и зеленчуци и други хранителни отпадъци в полезна смес за наторяване на растенията у дома, вместо да ги изхвърлим в кофата за боклук? Попаднали в сметището те гният и отделят в атмосферата вещества, които причиняват парников ефект и глобално затопляне!</p>"
          actionButtonTitle="Започни отначало"
          onComplete={() => {}}
        />
      </div>
      {
        screenDimensions.width < screenDimensions.height && alertModalDisplayed && (
          <AlertModal title="Препоръка" message="За по-добро усещане докато играете играта, завъртете вашето устройство!" closeModalAction={() => {
            setAlertModalDisplayed(false);
          }} />
        )
      }
    </>
  );
}

export default GameScreen;
