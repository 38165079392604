import { memo } from 'react';
import { Link } from 'react-router-dom';
import { CampaignSiteUrl } from '../../data';

export const ContentScreenFooter = memo((props) => {
  return (
    <div className="ContentScreenFooter">
      {
        props.showButtons === true && (
          <Link to="terms" className="AppButtonLight Button">Обща информация</Link>
        )
      }
      <div className="ContentScreenFooterContent">
        <p>
          <a href="https://www.eufunds.bg">www.eufunds.bg</a>
        </p>
        <p>
          Проектът е финансиран от Европейския фонд за регионално развитие на ЕС
          по приоритетна ос 2 „Отпадъци“ на ОП „Околна среда 2014 – 2020 г“
        </p>
      </div>
      {
        props.showButtons === true && (
          <a href={CampaignSiteUrl} className="AppButtonLight Button" target="_blank">Към сайта на кампанията</a>
        )
      }
    </div>
  )
})
