export const SuccessMessages = {
  'l-1': 'Един тон рециклирана пластмаса спестява електричеството на едно българско домакинство за две години.',
  'l-2': 'Всяка стъклена бутилка, която рециклираме спестява енергията, с която вашият компютър може да работи 25 минути.',
  'l-3': 'Една рециклирана алуминиева кутия (кенче) спестява енергия, която ще ни осигури 3 часа гледане на телевизия.',
  'l-4': 'Алуминият – металът, от който са направени кенчетата се рециклира изцяло и процесът може да бъде безкраен.',
  'l-5': 'Смачквайте пластмасовите бутилки преди да ги изхвърлите. Така контейнерът ще побере повече отпадъци и ще икономисаме гориво за транспорта и ще намалим вредните емисии.'
};

export const TrashManagementQuestions = [
  {
    'question': 'Какво е компостиране?',
    'answers': [
      {
        'title': 'Изгаряне на отпадъци',
        'correct': false
      },
      {
        'title': 'Засаждане на семе или луковица',
        'correct': false
      },
      {
        'title': 'Естествен начин на рециклиране на органични отпадъци',
        'correct': true
      },
      {
        'title': 'Разделно събиране на отпадъци',
        'correct': false
      }
    ]
  },
  {
    'question': 'Кое от следните НЯМА да помогне за намаляване на отпадъците, когато пазаруваме?',
    'answers': [
      {
        'title': 'Купуване на продукти, които могат да се рециклират',
        'correct': false
      },
      {
        'title': 'Купуване на няколко малки опаковки от един продукт',
        'correct': true
      },
      {
        'title': 'Използване на пазарска чанта за многократна употреба',
        'correct': false
      },
      {
        'title': 'Пазаруване на едро /в големи опаковки/',
        'correct': false
      }
    ]
  },
  {
    'question': 'Кой от следните отпадъци може да се рециклира многократно без органичение?',
    'answers': [
      {
        'title': 'Алуминиево кенче',
        'correct': true
      },
      {
        'title': 'Стар вестник',
        'correct': false
      },
      {
        'title': 'Пластмасова бутилка',
        'correct': false
      },
      {
        'title': 'Картонена кутия от обувки',
        'correct': false
      }
    ],
  },
  {
    'question': 'Общото количество на отпадъците от домакинствата в България за 2020 година е:',
    'answers': [
      {
        'title': '2 826 000 тона',
        'correct': true
      },
      {
        'title': '2 826 тона',
        'correct': false
      },
      {
        'title': '282 600 тона',
        'correct': false
      },
      {
        'title': '28 260 тона',
        'correct': false
      }
    ],
  },
  {
    'question': 'Кой от следните отпадъци НЕ може да се рециклира?',
    'answers': [
      {
        'title': 'Кенче от безалкохолна напитка',
        'correct': false
      },
      {
        'title': 'Картонена опаковка от сок',
        'correct': false
      },
      {
        'title': 'Електрическа крушка',
        'correct': true
      },
      {
        'title': 'Стъклен буркан от компот',
        'correct': false
      }
    ],
  },
  {
    'question': 'От 03.07.2021 г. в Европейския съюз, включително и България е забранено използването на:',
    'answers': [
      {
        'title': 'Стъклени бутилки',
        'correct': false
      },
      {
        'title': 'Пластмасови чинии',
        'correct': true
      },
      {
        'title': 'Картонени опаковки',
        'correct': false
      },
      {
        'title': 'Пластмасови бутилки',
        'correct': false
      }
    ],
  },
  {
    'question': 'Колко дървета спасява един тон рециклирана хартия?',
    'answers': [
      {
        'title': '9',
        'correct': false
      },
      {
        'title': '16',
        'correct': true
      },
      {
        'title': '6',
        'correct': false
      },
      {
        'title': '12',
        'correct': false
      }
    ],
  },
  {
    'question': 'Коя е най-добрата идея какво да правим с празно шише от минерална вода?',
    'answers': [
      {
        'title': 'Да го изхвърлим в кофа за боклук',
        'correct': false
      },
      {
        'title': 'Да сипваме в него вода или сок',
        'correct': false
      },
      {
        'title': 'Да направим хранилка за птици',
        'correct': true
      },
      {
        'title': 'Да го изхвърлим в храстите',
        'correct': false
      }
    ],
  }
];

export const FoodTrashesQuestions = [
  {
    'question': 'Останали са зеленчуци след вечерята. На следващия ден:',
    'answers': [
      {
        'title': 'Ще ги изхвърлим',
        'correct': false
      },
      {
        'title': 'Ще ги дадем на котката',
        'correct': false
      },
      {
        'title': 'Ще направим зеленчукова супа',
        'correct': true
      }
    ]
  },
  {
    'question': 'След приготвяне на вечерята е останало парче месо. Къде да го оставим?',
    'answers': [
      {
        'title': 'В кутията с плодове без опаковка',
        'correct': false
      },
      {
        'title': 'Опаковка в хладилника',
        'correct': true
      },
      {
        'title': 'Опаковка в шкафа',
        'correct': false
      }
    ]
  },
  {
    'question': 'Когато пазаруваме хранителни продукти:',
    'answers': [
      {
        'title': 'Взимаме всичко, което ни хареса',
        'correct': false
      },
      {
        'title': 'Правим списък на нужните продукти и се придържаме към него',
        'correct': true
      },
      {
        'title': 'Купуваме повече сладкиши и чипс',
        'correct': false
      }
    ]
  },
  {
    'question': 'Бананите в купата с плодове са започнали да покафеняват, затова:',
    'answers': [
      {
        'title': 'Ще направим бананов шейк',
        'correct': true
      },
      {
        'title': 'Ще ги изхвърлим',
        'correct': false
      },
      {
        'title': 'Ще ги оставим в купата',
        'correct': false
      }
    ]
  }
]
