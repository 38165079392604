import { memo } from 'react';

export const AlertModal = memo(function Modal({
  title,
  message,
  closeModalAction
}) {
  return (
    <div className="Modal Success">
      <div className="ModalWrap">
        <div className="ModalContent" style={{ alignItems: 'center' }}>
          <div className="ModalTitle">{title}</div>
          <div className="ModalMessage" style={{ textAlign: 'center' }}>{message}</div>
          <div className="AppButtonDark center" onClick={() => closeModalAction()}>OK</div>
        </div>
      </div>
    </div>
  )
})
