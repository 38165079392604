import { useCallback, useEffect, useState } from "react";
import { ActionModal } from "../ActionModal";
import { QuestionModal } from "../../components/trash-management-game/QuestionModal";
import { ASSETS_STORAGE_PRFIX_PREFIX } from "../../data";
import { debounce } from '../../helpers';

/*
 * questions: array
 * gameMusics: object
 * hasManualSelection: boolean
 * questionHideInterval: integer
 * successTitle: string
 * errorTitle: string
 * successMessage: string
 * errorMessage: string
 * actionButtonTitle: string
 * onComplete: function
 */
function QuestionsGame(props) {
  const [errorsCount, setErrorsCount] = useState(0);
  const [canvasDimensions, setCanvasDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
    marginLeft: 0,
    marginTop: 0
  });
  const [gameCompleted, setGameCompleted] = useState(0);
  const [rightAnsweredQuestions, setRightAnsweredQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(props.hasManualSelection ? null : 1);

  const gameMusics = props.gameMusics;
  const questions = props.questions;

  const handleResize = () => {
    let width = window.innerWidth;
    let height = window.innerHeight;
    let horizontalSpacing = -20;
    let verticalSpacing = -20;

    if((width / height) > (16 / 9)) {
      const realWidth = width;
      width = Math.floor(height * (16 / 9));
      horizontalSpacing = (realWidth - width) / 2;

      if(horizontalSpacing > 20) {
        horizontalSpacing -= 20;
      } else {
        horizontalSpacing = horizontalSpacing - 20;
      }
    } else {
      const realHeight = height;
      height = Math.floor(width * (9 / 16));
      verticalSpacing = (realHeight - height) / 2;

      if(verticalSpacing > 20) {
        verticalSpacing -= 20;
      } else {
        verticalSpacing = verticalSpacing - 20;
      }
    }

    setCanvasDimensions({
      height: height,
      width: width,
      marginLeft: horizontalSpacing,
      marginTop:verticalSpacing
    });
  }

  const onCanvasSquareClick = useCallback((squareId) => {
    setCurrentQuestionIndex(squareId);
  })

  const onQuestionSuccess = useCallback((question) => {
    gameMusics.playSuccess();
    setTimeout(() => {
      const nextQuestionIndex = props.hasManualSelection ?
          null :
          (currentQuestionIndex < questions.length ? currentQuestionIndex + 1 : null);

      setCurrentQuestionIndex(nextQuestionIndex);
      setRightAnsweredQuestions([...rightAnsweredQuestions, ...[currentQuestionIndex]]);

      if(rightAnsweredQuestions.length == questions.length - 1) {
        setGameCompleted(true);
      }
    }, props.questionHideInterval);
  })

  const onQuestionError = useCallback((question) => {
    gameMusics.playError();

    setTimeout(() => {
      if(props.hasManualSelection) {
        setCurrentQuestionIndex(null);
      }
      setErrorsCount(errorsCount + 1);
    }, props.questionHideInterval);
  })

  const onGameRestart = useCallback(() => {
    setCurrentQuestionIndex(props.hasManualSelection ? null : 1);
    setErrorsCount(0);
    setRightAnsweredQuestions([]);
    setGameCompleted(false);
    props.onComplete();
  })

  useEffect(() => {
    const debouncedHandleResize = debounce(handleResize, 1000);

    window.addEventListener('resize', debouncedHandleResize)

    return _ => {
      window.removeEventListener('resize', debouncedHandleResize);
    }
  }, []);

  useEffect(() => {
    handleResize();
  }, []);

  return (
    <>
      {
        props.hasManualSelection ?
        (
          <div className="TrashesCityContainer" style={{ height: canvasDimensions.height, width: canvasDimensions.width, marginLeft: canvasDimensions.marginLeft, marginTop: canvasDimensions.marginTop }}>
            {
              [1, 2, 3, 4, 5, 6, 7, 8].map((squareId) => {
                return (
                  !rightAnsweredQuestions.includes(squareId) ?
                  (
                    <div className={`TrashesCityContainerPart TrashesCityContainerPart${squareId}`} onClick={() => onCanvasSquareClick(squareId)} key={`TrashesCityContainerPart-${squareId}`}>
                      <img src={`${ASSETS_STORAGE_PRFIX_PREFIX}assets/images/trash-management-game/garbage-background/${squareId}.png`} />
                      {
                        currentQuestionIndex == squareId &&
                        (
                          <div className={`TrashesCityContainerPartOverlay`}></div>
                        )
                      }
                    </div>
                  ) :
                  (
                    <div className="TrashesCityContainerPart Answered"></div>
                  )
                )
              })
            }
          </div>
        ) : null
      }
      {
        currentQuestionIndex !== null &&
        (
          <QuestionModal
            questionIndex={currentQuestionIndex}
            question={questions[currentQuestionIndex - 1]}
            successCallback={onQuestionSuccess}
            errorCallback={onQuestionError}
            isFullWidth={props.hasManualSelection}
          />
        )
      }
      {
        errorsCount >= 3 || gameCompleted ?
        (
          <ActionModal
            title={gameCompleted ? props.successTitle : props.errorTitle}
            message={gameCompleted ? props.successMessage : props.errorMessage}
            isSuccess={gameCompleted}
            action={onGameRestart}
            actionButtonTitle={props.actionButtonTitle}
            nextLevelAction={() => {}}
            isLongText={true}
          />
        ) : null
      }
    </>
  );
}

export default QuestionsGame;
