import { Link } from "react-router-dom";
import { ContentScreenFooter } from "../components/common/ContentScreenFooter";
import { ContentScreenHeader } from "../components/common/ContentScreenHeader";
import { FOOD_TRASHES_GAME_PREFIX, RECYCLING_GAME_PREFIX, TRASH_MANAGEMENT_GAME_PREFIX } from "../data";

function StartScreen() {
  return (
    <div className="ContentScreen StartScreen">
      <div className="ScreenWrap">
        <ContentScreenHeader />
        <div className="ContentScreenContent">
          <div className="StartScreenChooseGame">
            Избери игра:
          </div>
          <div className="StartScreenGameLaunchersContainer">
            <Link to={`/${RECYCLING_GAME_PREFIX}`}>
              <div className="StartScreenGameLauncher Recycling">
                Игра за<br/>рециклиране
              </div>
            </Link>
            <Link to={`/${TRASH_MANAGEMENT_GAME_PREFIX}`}>
              <div className="StartScreenGameLauncher TrashManagement">
                ИГРА ЗА<br/>УПРАВЛЕНИЕ<br/>НА ОТПАДЪЦИ
              </div>
            </Link>
            <Link to={`/${FOOD_TRASHES_GAME_PREFIX}`}>
              <div className="StartScreenGameLauncher TrashFood">
                ИГРА ЗА<br/>ХРАНИТЕЛНИ<br/>ОТПАДЪЦИ
              </div>
            </Link>
            <div className="StartScreenKids"></div>
          </div>
        </div>
        <ContentScreenFooter showButtons={true} />
      </div>
    </div>
  );
}

export default StartScreen;
