import { memo } from 'react';
import { Link } from "react-router-dom";
import { CampaignSiteUrl } from '../../data/Constants';

export const TrashesGameActionModal = memo(function Modal({
  title,
  message,
  isSuccess,
  restartGameAction,
  nextLevelAction,
  level,
  levelsCount
}) {
  return (
    <div className="GameModal SquirrelGameModal Modal">
      <div className="ModalWrap">
        <div className="ModalSquirrel"></div>
        <div className="ModalContent">
          <div className="ModalTitle">{title}</div>
          <div className="ModalMessage">{message}</div>
          {
            isSuccess ?
            (
              level == levelsCount ?
              (
                <>
                  <div className="AppButtonDark right" onClick={() => restartGameAction()}>Започни отначало</div>
                  <a href={CampaignSiteUrl} className="AppButtonLight" target="_blank">Към сайта на кампанията</a>
                  <Link to="/terms" className="AppButtonLight">Обща информация</Link>
                </>
              ) :
              (
                <>
                  <div className="ModalNextLevelButton AppButtonDark right" onClick={() => nextLevelAction()}>Следващо ниво</div>
                  <div className="AppButtonLight" onClick={() => restartGameAction()}>Започни отначало</div>
                </>
              )
            ) :
            (
              <div className="AppButtonDark right" onClick={() => restartGameAction()}>Опитай отново</div>
            )
          }
        </div>
      </div>
    </div>
  )
})
