import { useEffect, useState } from "react";

const useAudio = (audioSource, repeat, intialVolume) => {
  const [audio] = useState(new Audio(audioSource));
  const [volume, setVolume] = useState(intialVolume);
  audio.loop = repeat;
  audio.volume = volume;
  const [playing, setPlaying] = useState(false);

  const play = () => {
    audio.play();
  };

  const stop = () => {
    audio.pause();
  };

  const mute = () => {
    setVolume(0);
  }

  const unMute = () => {
    setVolume(intialVolume);
  }

  return {
    playing, play, stop, mute, unMute
  };
};

export default useAudio;
