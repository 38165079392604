import { useState } from "react";
import { ActionModal } from "../ActionModal";

/*
 * rightOrdering: array
 * gameWrapperClass: string
 * orderingSteps: array
 * onComplete: function
 */
function OrderingGame(props) {
  const [currentOrdering, setCurrentOrdering] = useState([0, 0, 0, 0, 0, 0]);
  const [isSuccessfulOrdering, setIsSuccessfulOrdering] = useState(true);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [globalState, setGlobalState] = useState({
    successfulOrdering: false,
    completed: false
  });

  function setOrdering(index, value) {
    const ordering = currentOrdering;
    ordering[index] = parseInt(value);
    setCurrentOrdering([...ordering]);
  }

  function checkOrdering()
  {
    let isRightOrdering = true;
    props.rightOrdering.forEach((item, index) => {
      if(currentOrdering[index] !== item) {
        isRightOrdering = false;
      }
    });

    setIsSuccessfulOrdering(isRightOrdering);
    setShowMessageModal(true);
  }

  function completeGame()
  {
    setShowMessageModal(false);
    if(isSuccessfulOrdering) {
      props.onComplete(true);
    }
  }

  function restartGame()
  {
    setCurrentOrdering([0, 0, 0, 0, 0, 0]);
    setShowMessageModal(false);
  }

  return (
    <>
      <div className={props.gameWrapperClass}>
        <div className="OrderingGameStepsPreview">
          {
            props.orderingSteps.map((item, index) => {
              return (
                <div className={`OrderingStepPreview OrderingStepPreview${index + 1}`} key={`OrderingStepPreview${index + 1}`}>
                  <div className="OrderingStepPreviewTitle">{item}</div>
                  <div className={`OrderingStepPreviewImage OrderingStepPreviewImage${index + 1}`}></div>
                </div>
              );
            })
          }
        </div>
        <div className="OrderingGameStepsFill">
          {props.orderingSteps.map(({ type, lastDroppedItem }, index) => (
            <div className={`OrderingStepFill OrderingStepFill${index + 1}`} key={`OrderingStepFill${index + 1}`}>
              <input
                type="text"
                name={`OrderingStepFill${index + 1}`}
                onChange={(e) => {
                  setOrdering(index, e.target.value);
                }}
                value={currentOrdering[index] > 0 ? currentOrdering[index] : ''}
                />
            </div>
          ))}
        </div>
        <div className="OrderingGameStepsCheckContainer">
          <div className="AppButtonDark right" onClick={() => {
            checkOrdering();
          }} style={{ maxWidth: '200px' }}>Провери подредбата</div>
        </div>
      </div>
      {
        showMessageModal ?
        (
          <ActionModal
            title={isSuccessfulOrdering ? "Поздравления! Ти Успя." : "Опитай отново!"}
            message={isSuccessfulOrdering ? "Успешно подреди стъпките, по които храната минава докато стигне до твоя хладилник!" : "Има някои грешки, опитай се да ги откриеш и поправиш!"}
            isSuccess={isSuccessfulOrdering}
            action={isSuccessfulOrdering ? completeGame : restartGame}
            actionButtonTitle={isSuccessfulOrdering ? "Следващо ниво" : "Започни отначало"}
          />
        ) : null
      }
    </>
  );
}

export default OrderingGame;
