import { TrashItemTypes } from './TrashItemTypes';

export const Trashes = {
  'l-1': [
    { name: 'apple', type: TrashItemTypes.COMPOSTING, hasFails: false, top: 0, left: 0 },
    { name: 'bag', type: TrashItemTypes.PLASTIC, hasFails: false, top: 0, left: 0 },
    { name: 'battery', type: TrashItemTypes.ELECTRONICS, hasFails: false, top: 0, left: 0 },
    { name: 'bottle', type: TrashItemTypes.PLASTIC, hasFails: false, top: 0, left: 0 },
    { name: 'bottle-2', type: TrashItemTypes.GLASS, hasFails: false, top: 0, left: 0 },
    { name: 'box', type: TrashItemTypes.PAPER, hasFails: false, top: 0, left: 0 },
    { name: 'bulb', type: TrashItemTypes.NOT_RECYLABLE, hasFails: false, top: 0, left: 0 },
    { name: 'can', type: TrashItemTypes.PLASTIC, hasFails: false, top: 0, left: 0 },
    { name: 'cheese', type: TrashItemTypes.COMPOSTING, hasFails: false, top: 0, left: 0 },
    { name: 'cup', type: TrashItemTypes.PLASTIC, hasFails: false, top: 0, left: 0 },
    { name: 'glass', type: TrashItemTypes.NOT_RECYLABLE, hasFails: false, top: 0, left: 0 },
    { name: 'gloves', type: TrashItemTypes.PLASTIC, hasFails: false, top: 0, left: 0 },
    { name: 'jar', type: TrashItemTypes.GLASS, hasFails: false, top: 0, left: 0 },
    { name: 'leaf', type: TrashItemTypes.COMPOSTING, hasFails: false, top: 0, left: 0 },
    { name: 'monitor', type: TrashItemTypes.ELECTRONICS, hasFails: false, top: 0, left: 0 },
    { name: 'paper', type: TrashItemTypes.PAPER, hasFails: false, top: 0, left: 0 },
    { name: 'phone', type: TrashItemTypes.ELECTRONICS, hasFails: false, top: 0, left: 0 },
    { name: 'pills', type: TrashItemTypes.NOT_RECYLABLE, hasFails: false, top: 0, left: 0 },
    { name: 'pizza', type: TrashItemTypes.COMPOSTING, hasFails: false, top: 0, left: 0 },
    { name: 'plate', type: TrashItemTypes.NOT_RECYLABLE, hasFails: false, top: 0, left: 0 }
  ],
  'l-2': [
    { name: 'apple', type: TrashItemTypes.COMPOSTING, hasFails: false, top: 0, left: 0 },
    { name: 'bag', type: TrashItemTypes.PAPER, hasFails: false, top: 0, left: 0 },
    { name: 'bag-2', type: TrashItemTypes.PLASTIC, hasFails: false, top: 0, left: 0 },
    { name: 'banana', type: TrashItemTypes.COMPOSTING, hasFails: false, top: 0, left: 0 },
    { name: 'battery', type: TrashItemTypes.ELECTRONICS, hasFails: false, top: 0, left: 0 },
    { name: 'bottle', type: TrashItemTypes.GLASS, hasFails: false, top: 0, left: 0 },
    { name: 'box', type: TrashItemTypes.PAPER, hasFails: false, top: 0, left: 0 },
    { name: 'calculator', type: TrashItemTypes.ELECTRONICS, hasFails: false, top: 0, left: 0 },
    { name: 'can', type: TrashItemTypes.PLASTIC, hasFails: false, top: 0, left: 0 },
    { name: 'can-2', type: TrashItemTypes.PLASTIC, hasFails: false, top: 0, left: 0 },
    { name: 'corn', type: TrashItemTypes.COMPOSTING, hasFails: false, top: 0, left: 0 },
    { name: 'cup', type: TrashItemTypes.PAPER, hasFails: false, top: 0, left: 0 },
    { name: 'cup-2', type: TrashItemTypes.NOT_RECYLABLE, hasFails: false, top: 0, left: 0 },
    { name: 'diaper', type: TrashItemTypes.NOT_RECYLABLE, hasFails: false, top: 0, left: 0 },
    { name: 'flower', type: TrashItemTypes.COMPOSTING, hasFails: false, top: 0, left: 0 },
    { name: 'paper', type: TrashItemTypes.PAPER, hasFails: false, top: 0, left: 0 },
    { name: 'phone', type: TrashItemTypes.ELECTRONICS, hasFails: false, top: 0, left: 0 },
    { name: 'pizza', type: TrashItemTypes.COMPOSTING, hasFails: false, top: 0, left: 0 },
    { name: 'plate', type: TrashItemTypes.NOT_RECYLABLE, hasFails: false, top: 0, left: 0 },
    { name: 'torch', type: TrashItemTypes.ELECTRONICS, hasFails: false, top: 0, left: 0 }
  ],
  'l-3': [
    { name: 'apple', type: TrashItemTypes.COMPOSTING, hasFails: false, top: 0, left: 0 },
    { name: 'bag', type: TrashItemTypes.PAPER, hasFails: false, top: 0, left: 0 },
    { name: 'bag-2', type: TrashItemTypes.PLASTIC, hasFails: false, top: 0, left: 0 },
    { name: 'bag-3', type: TrashItemTypes.PAPER, hasFails: false, top: 0, left: 0 },
    { name: 'battery', type: TrashItemTypes.ELECTRONICS, hasFails: false, top: 0, left: 0 },
    { name: 'battery-2', type: TrashItemTypes.ELECTRONICS, hasFails: false, top: 0, left: 0 },
    { name: 'bottle', type: TrashItemTypes.PLASTIC, hasFails: false, top: 0, left: 0 },
    { name: 'bottle-2', type: TrashItemTypes.PLASTIC, hasFails: false, top: 0, left: 0 },
    { name: 'box', type: TrashItemTypes.PAPER, hasFails: false, top: 0, left: 0 },
    { name: 'cup', type: TrashItemTypes.PAPER, hasFails: false, top: 0, left: 0 },
    { name: 'cup-2', type: TrashItemTypes.PLASTIC, hasFails: false, top: 0, left: 0 },
    { name: 'diaper', type: TrashItemTypes.NOT_RECYLABLE, hasFails: false, top: 0, left: 0 },
    { name: 'jar', type: TrashItemTypes.GLASS, hasFails: false, top: 0, left: 0 },
    { name: 'paper', type: TrashItemTypes.PAPER, hasFails: false, top: 0, left: 0 },
    { name: 'phone', type: TrashItemTypes.ELECTRONICS, hasFails: false, top: 0, left: 0 },
    { name: 'pizza', type: TrashItemTypes.COMPOSTING, hasFails: false, top: 0, left: 0 },
    { name: 'spray-bottle', type: TrashItemTypes.PLASTIC, hasFails: false, top: 0, left: 0 },
    { name: 'tea', type: TrashItemTypes.COMPOSTING, hasFails: false, top: 0, left: 0 },
    { name: 'tube', type: TrashItemTypes.PLASTIC, hasFails: false, top: 0, left: 0 },
    { name: 'watermelon', type: TrashItemTypes.COMPOSTING, hasFails: false, top: 0, left: 0 }
  ],
  'l-4': [
    { name: 'bag', type: TrashItemTypes.PLASTIC, hasFails: false, top: 0, left: 0 },
    { name: 'bag-2', type: TrashItemTypes.PAPER, hasFails: false, top: 0, left: 0 },
    { name: 'battery', type: TrashItemTypes.ELECTRONICS, hasFails: false, top: 0, left: 0 },
    { name: 'battery-2', type: TrashItemTypes.ELECTRONICS, hasFails: false, top: 0, left: 0 },
    { name: 'bone', type: TrashItemTypes.COMPOSTING, hasFails: false, top: 0, left: 0 },
    { name: 'bottle', type: TrashItemTypes.GLASS, hasFails: false, top: 0, left: 0 },
    { name: 'bottle-2', type: TrashItemTypes.PLASTIC, hasFails: false, top: 0, left: 0 },
    { name: 'camera', type: TrashItemTypes.ELECTRONICS, hasFails: false, top: 0, left: 0 },
    { name: 'can', type: TrashItemTypes.PLASTIC, hasFails: false, top: 0, left: 0 },
    { name: 'can-2', type: TrashItemTypes.PLASTIC, hasFails: false, top: 0, left: 0 },
    { name: 'chicken', type: TrashItemTypes.COMPOSTING, hasFails: false, top: 0, left: 0 },
    { name: 'cup', type: TrashItemTypes.PAPER, hasFails: false, top: 0, left: 0 },
    { name: 'cup-2', type: TrashItemTypes.NOT_RECYLABLE, hasFails: false, top: 0, left: 0 },
    { name: 'diaper', type: TrashItemTypes.NOT_RECYLABLE, hasFails: false, top: 0, left: 0 },
    { name: 'fish', type: TrashItemTypes.COMPOSTING, hasFails: false, top: 0, left: 0 },
    { name: 'jar', type: TrashItemTypes.GLASS, hasFails: false, top: 0, left: 0 },
    { name: 'tommatoe', type: TrashItemTypes.COMPOSTING, hasFails: false, top: 0, left: 0 },
    { name: 'torch', type: TrashItemTypes.ELECTRONICS, hasFails: false, top: 0, left: 0 },
    { name: 'watermelon', type: TrashItemTypes.COMPOSTING, hasFails: false, top: 0, left: 0 },
    { name: 'wrench', type: TrashItemTypes.PLASTIC, hasFails: false, top: 0, left: 0 },
  ],
  'l-5': [
    { name: 'bag', type: TrashItemTypes.PAPER, hasFails: false, top: 0, left: 0 },
    { name: 'battery', type: TrashItemTypes.ELECTRONICS, hasFails: false, top: 0, left: 0 },
    { name: 'bottle', type: TrashItemTypes.PLASTIC, hasFails: false, top: 0, left: 0 },
    { name: 'bottle-2', type: TrashItemTypes.GLASS, hasFails: false, top: 0, left: 0 },
    { name: 'box', type: TrashItemTypes.PAPER, hasFails: false, top: 0, left: 0 },
    { name: 'can', type: TrashItemTypes.PLASTIC, hasFails: false, top: 0, left: 0 },
    { name: 'can-2', type: TrashItemTypes.PLASTIC, hasFails: false, top: 0, left: 0 },
    { name: 'carrot', type: TrashItemTypes.COMPOSTING, hasFails: false, top: 0, left: 0 },
    { name: 'cucumber', type: TrashItemTypes.COMPOSTING, hasFails: false, top: 0, left: 0 },
    { name: 'cup', type: TrashItemTypes.NOT_RECYLABLE, hasFails: false, top: 0, left: 0 },
    { name: 'diaper', type: TrashItemTypes.NOT_RECYLABLE, hasFails: false, top: 0, left: 0 },
    { name: 'jug', type: TrashItemTypes.GLASS, hasFails: false, top: 0, left: 0 },
    { name: 'keyboard', type: TrashItemTypes.ELECTRONICS, hasFails: false, top: 0, left: 0 },
    { name: 'mouse', type: TrashItemTypes.ELECTRONICS, hasFails: false, top: 0, left: 0 },
    { name: 'paper', type: TrashItemTypes.COMPOSTING, hasFails: false, top: 0, left: 0 },
    { name: 'pills', type: TrashItemTypes.NOT_RECYLABLE, hasFails: false, top: 0, left: 0 },
    { name: 'plate', type: TrashItemTypes.NOT_RECYLABLE, hasFails: false, top: 0, left: 0 },
    { name: 'tablet', type: TrashItemTypes.ELECTRONICS, hasFails: false, top: 0, left: 0 },
    { name: 'tube', type: TrashItemTypes.PLASTIC, hasFails: false, top: 0, left: 0 },
    { name: 'watermelon', type: TrashItemTypes.COMPOSTING, hasFails: false, top: 0, left: 0 }
  ]
};

export const FoodTrashes = {
  'l-1': [
    { name: 'biskviti', type: TrashItemTypes.CLOSET, hasFails: false, top: 0, left: 0 },
    { name: 'bob', type: TrashItemTypes.CLOSET, hasFails: false, top: 0, left: 0 },
    { name: 'domati', type: TrashItemTypes.FRIDGE, hasFails: false, top: 0, left: 0 },
    { name: 'grozde', type: TrashItemTypes.FRIDGE, hasFails: false, top: 0, left: 0 },
    { name: 'zahar', type: TrashItemTypes.CLOSET, hasFails: false, top: 0, left: 0 },
    { name: 'kiselo-mlqko', type: TrashItemTypes.FRIDGE, hasFails: false, top: 0, left: 0 },
    { name: 'makaroni', type: TrashItemTypes.CLOSET, hasFails: false, top: 0, left: 0 },
    { name: 'mlqko', type: TrashItemTypes.FRIDGE, hasFails: false, top: 0, left: 0 },
    { name: 'pica', type: TrashItemTypes.FRIDGE, hasFails: false, top: 0, left: 0 },
    { name: 'qgodi', type: TrashItemTypes.FRIDGE, hasFails: false, top: 0, left: 0 },
    { name: 'qica', type: TrashItemTypes.FRIDGE, hasFails: false, top: 0, left: 0 },
    { name: 'salam', type: TrashItemTypes.FRIDGE, hasFails: false, top: 0, left: 0 },
    { name: 'sirene', type: TrashItemTypes.FRIDGE, hasFails: false, top: 0, left: 0 },
    { name: 'sladoled', type: TrashItemTypes.FREEZER, hasFails: false, top: 0, left: 0 }
  ]
}
