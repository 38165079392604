import { memo } from 'react';
import CustomScroll from 'react-custom-scroll';

export const InfoModal = memo(function Modal({
  closeModalAction
}) {
  return (
    <div className="Modal">
      <div className="ModalWrap">
        <div className="ModalContent">
          <div className="ModalTitle">Информация</div>
          <div className="ModalMessage">
            <CustomScroll heightRelativeToParent="100%">
              <p>
              За да преминеш на следващо ниво трябва да събереш минимум 150 точки. Всеки правилно изхвърлен отпадък от първия опит дава по 10 точки. Ако не уцелиш от първия път след това не получваш точки за правилно изхвърляне при следващ опит.
              </p>
              <p className="ContentScreenContentContentTitle">
                Цветове на контейнери и видове на отпадъци:
              </p>

              <div className="ContentScreenContentTrashCanContainer">
                <div className="ContentScreenContentTrashCanRow">
                  <div className="ContentScreenContentTrashCanWrap">
                    <div className="ContentScreenContentTrashCan Gray"></div>
                    <div className="ContentScreenContentTrashCanDescription">
                      Отпадъци, които не могат да се рециклират
                    </div>
                  </div>
                  <div className="ContentScreenContentTrashCanWrap">
                    <div className="ContentScreenContentTrashCan Yellow"></div>
                    <div className="ContentScreenContentTrashCanDescription">
                      Пластмасови и метални отпадъци
                    </div>
                  </div>
                  <div className="ContentScreenContentTrashCanWrap">
                    <div className="ContentScreenContentTrashCan Green"></div>
                    <div className="ContentScreenContentTrashCanDescription">
                      Стъклени отпадъци
                    </div>
                  </div>
                </div>
              </div>

              <div className="ContentScreenContentTrashCanContainer">
                <div className="ContentScreenContentTrashCanRow">
                  <div className="ContentScreenContentTrashCanWrap">
                    <div className="ContentScreenContentTrashCan Blue"></div>
                    <div className="ContentScreenContentTrashCanDescription">
                      Хартиени и картонени отпадъци
                    </div>
                  </div>
                  <div className="ContentScreenContentTrashCanWrap">
                    <div className="ContentScreenContentTrashCan Brown"></div>
                    <div className="ContentScreenContentTrashCanDescription">
                      Отпадъци за компостиране
                    </div>
                  </div>
                  <div className="ContentScreenContentTrashCanWrap">
                    <div className="ContentScreenContentTrashCan Red"></div>
                    <div className="ContentScreenContentTrashCanDescription">
                      Електронни отпадъци
                    </div>
                  </div>
                </div>
              </div>
            </CustomScroll>
          </div>
          <div className="AppButtonDark left" onClick={() => closeModalAction()}>Обратно в играта</div>
        </div>
      </div>
    </div>
  )
})
