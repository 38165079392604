import { useState } from 'react';
import { Link } from "react-router-dom";
import { InfoModal, TrashGame } from '../../components';
import { Trashes, TrashItemTypes } from '../../data';
import backgroundAudio from '../../assets/audio/background.mp3';
import useGameMusics from '../../hooks/useGameMusics';

function TrashGameScreen(props) {
  const audioMuted = props.audioMuted;
  const setAudioMuted = props.setAudioMuted;
  const gameMusics = useGameMusics(backgroundAudio, setAudioMuted);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [trashesGamePublicState, setTrashesGamePublicState] = useState({});

  return (
    <div className={`GameCanvasScreen GameCanvasScreen-l-${trashesGamePublicState.level}`}>
      <div className="GameCanvasScreenHeader">
        <div className="GameCanvasScreenHeaderRow">
          <div className="GameCanvasScreenHeaderLeftButtons">
            <Link to={`/recycling`} className="GoToHomeButton"></Link>
          </div>
          <div className="GameCanvasScreenPointsContainer">
            <div className="GameCanvasScreenPoints">
              Точки: {trashesGamePublicState.points ?? 0}
            </div>
          </div>
          <div className="GameCanvasScreenHeaderRightButtons">
            <div className="InfoButton" onClick={() => {
              setShowInfoModal(true);
            }}></div>
            <div className={`AudioButton ${audioMuted ? 'muted' : 'playing'}`} onClick={
              () => {
                gameMusics.toggleAudio(audioMuted)
              }
            }></div>
          </div>
        </div>
        <div className="GameCanvasScreenHeaderRow center">
          <div className="GameCanvasScreenPointsContainer">
            <div className="GameCanvasScreenExplain">
              Постави отпадъците в правилния контейнер.
            </div>
          </div>
        </div>
      </div>
      <TrashGame
        dustbins={
          [
            TrashItemTypes.NOT_RECYLABLE,
            TrashItemTypes.PLASTIC,
            TrashItemTypes.GLASS,
            TrashItemTypes.PAPER,
            TrashItemTypes.COMPOSTING,
            TrashItemTypes.ELECTRONICS
          ]
        }
        gameMusics={gameMusics}
        gameWrapperClass="TrashesGameContainer"
        levelsCount={5}
        pointsPerItem={10}
        setPublicState={(state) => { setTrashesGamePublicState(state)}}
        successPercentage={75}
        trashes={Trashes}
        trashesBasePath="assets/images"
        isFinalGame={true}
        onComplete={() => {}}
      ></TrashGame>
      {
        showInfoModal ?
        (
          <InfoModal
            closeModalAction={() => setShowInfoModal(false)}
          />
        ) : null
      }
    </div>
  );
}

export default TrashGameScreen;
