import React, { useEffect, useState } from 'react';
import {  Routes, Route } from 'react-router-dom';
import './styles/App.css';
import { RECYCLING_GAME_PREFIX, TRASH_MANAGEMENT_GAME_PREFIX, FOOD_TRASHES_GAME_PREFIX } from './data';
import StartScreen from './screens/StartScreen';
import {
  IntroScreen as RecyclingIntroScreen,
  TermsScreen,
  TrashGameScreen
} from './screens/recycling-game';
import {
  IntroScreen as TrashManagmentIntroScreen,
  GameScreen as TrashManagementGameScreen
} from './screens/trash-management-game';
import {
  IntroScreen as FoodTrashesIntroScreen,
  GameScreen as FoodTrashesGameScreen
} from './screens/food-trashes-game';
import {debounce} from './helpers';

function App() {
  const [audioMuted, setAudioMuted] = useState(false);
  const [screenDimensions, setScreenDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  useEffect(() => {
    document.title = "Think green";

    const debouncedHandleResize = debounce(function handleResize() {
      setScreenDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }, 10);

    window.addEventListener('resize', debouncedHandleResize)

    return _ => {
      window.removeEventListener('resize', debouncedHandleResize);
    }
  }, []);

  return (
    <div className={`App ${screenDimensions.width > screenDimensions.height ? 'horizontal' : 'vertical'}`}>
      <div className="AppWrap">
        <Routes>
          <Route exact path="/" element={<StartScreen />}/>
          <Route
            element={<TermsScreen />}
            exact
            path={`/terms`}
          />
          <Route
            element={<RecyclingIntroScreen />}
            exact
            path={`/${RECYCLING_GAME_PREFIX}`}
          />
          <Route
            element={<TrashGameScreen
              audioMuted={audioMuted}
              setAudioMuted={setAudioMuted}
            />}
            exact
            path={`/${RECYCLING_GAME_PREFIX}/game`}
          />
          <Route
            element={<TermsScreen />}
            exact
            path={`/${RECYCLING_GAME_PREFIX}/terms`}
          />
          <Route
            element={<TrashManagmentIntroScreen />}
            exact
            path={`/${TRASH_MANAGEMENT_GAME_PREFIX}`}
          />
          <Route
            element={<TrashManagementGameScreen
              audioMuted={audioMuted}
              setAudioMuted={setAudioMuted}
            />}
            exact
            path={`/${TRASH_MANAGEMENT_GAME_PREFIX}/game`}
          />
          <Route
            element={<TermsScreen />}
            exact
            path={`/${TRASH_MANAGEMENT_GAME_PREFIX}/terms`}
          />
          <Route
            element={<FoodTrashesIntroScreen />}
            exact
            path={`/${FOOD_TRASHES_GAME_PREFIX}`}
          />
          <Route
            element={<FoodTrashesGameScreen />}
            exact
            path={`/${FOOD_TRASHES_GAME_PREFIX}/game`}
          />
          <Route
            element={<TermsScreen />}
            exact
            path={`/${FOOD_TRASHES_GAME_PREFIX}/terms`}
          />
        </Routes>
      </div>
    </div>
  );
}

export default App;
