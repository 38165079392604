import { memo, useEffect, useState } from 'react';

export const QuestionModal = memo(function Modal({
  questionIndex,
  question,
  successCallback,
  errorCallback,
  isFullWidth
}) {
  const [clickedAnswerIndex, setClickedAnswerIndex] = useState(null);
  const letters = ['А', 'Б', 'В', 'Г'];

  useEffect(() => {
    setClickedAnswerIndex(null);
  }, [question])

  return (
    <div className={`Modal QuestionModal Question${questionIndex}`}>
      <div className="ModalWrap">
        <div className={`ModalContent${isFullWidth ? ' Full' : ''}`}>
          <div className="QuestionTitle">{`${questionIndex}. ${question.question}`}</div>
          <div className="QuestionAnswers">
            {
              question.answers.map((answer, index) => {
                let additionalClassname = '';
                if(clickedAnswerIndex == index) {
                  if(answer.correct) {
                    additionalClassname = 'Correct';
                  } else {
                    additionalClassname = 'Wrong';
                  }
                }
                return (
                  <div className={`QuestionAnswer ${additionalClassname}`} key={`QuestionAnswer-${index}`} onClick={() => {
                    setClickedAnswerIndex(index);
                    answer.correct ? successCallback(question) : errorCallback(question)
                  }}>
                    {letters[index]}. {answer.title}
                  </div>
                )
              })
            }
          </div>
        </div>
        {
          !isFullWidth ?
          (
            <div className="QuestionPictures"></div>
          ) : null
        }
      </div>
    </div>
  )
})
