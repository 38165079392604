import CustomScroll from 'react-custom-scroll';
import { Link } from "react-router-dom";
import { ContentScreenFooter } from '../../components/common/ContentScreenFooter';
import { ContentScreenHeader } from '../../components/common/ContentScreenHeader';
import { ASSETS_STORAGE_PRFIX_PREFIX, CampaignSiteUrl } from '../../data/Constants';

function ContentScreen() {
  return (
    <div className="ContentScreen">
      <div className="ScreenWrap">
        <ContentScreenHeader showHomeButton={true} />
        <div className="ContentScreenContent">
          <div className="ContentScreenContentLeft">
            <div className="ContentScreenContentHeader">Игра за рециклиране</div>
            <div className="ContentScreenContentContent">
              <CustomScroll heightRelativeToParent="100%">
                <p>
                  Познавате ли този символ?<br/>
                  Знаете ли какво означават трите стрелки?
                </p>
                <p>
                  <table>
                    <tr>
                      <td style={{paddingTop: "10px"}}>
                        <img src={`${ASSETS_STORAGE_PRFIX_PREFIX}assets/images/recycle_icon.png`} style={{width: "70px"}} />
                      </td>
                      <td>
                        <ul style={{paddingLeft: "50px", margin: 0}}>
                          <li>Намали отпадъците</li>
                          <li>Използвай повторно</li>
                          <li>Рециклирай</li>
                        </ul>
                      </td>
                    </tr>
                  </table>

                </p>

                <p>
                Населението на Земята е над 7,753 милиарда. Представете си какво ще се случи, ако всеки просто изхвърля отпадъците и ненужните неща!
                </p>
                <p>
                Трябва да се стараем да намалим отпадъците.
                </p>
                <p>
                Всяка година в света се изхвърлят милиони тонове хартия, а за производството на един тон хартия се отсичат 16 дървета!
                </p>
                <p>
                Пластмасовите отпадъци в световния океан са толкова много, че образуват плаващи острови и  са сериозна заплаха за живота на морските обитатели.
                </p>
                <p>
                Гниещите отпадъци в сметищата отделят опасни газове, които са причина за глобалното затопляне.
                </p>
                <p>
                Кухненските и градинските отпадъци могат да се превърнат в полезен продукт – органична тор за вашите растения – компост. Това става с помощта на милиони малки същества, повечето  от които не се виждат с просто око, а процесът се нарича компостиране.
                Когато рециклираме отпадъци ние спестяваме ценни природни ресурси, икономисваме енергия, намаляваме замърсяването на природата и се грижим за собственото си здраве.
                Затова рециклирането на отпадъци е толкова важно за хората, животните и природата.
                </p>
                <p className="ContentScreenContentContentTitle">
                  Цветове на контейнери и видове на отпадъци:
                </p>

                <div className="ContentScreenContentTrashCanContainer">
                  <div className="ContentScreenContentTrashCanRow">
                    <div className="ContentScreenContentTrashCanWrap">
                      <div className="ContentScreenContentTrashCan Gray"></div>
                      <div className="ContentScreenContentTrashCanDescription">
                        Отпадъци, които не могат да се рециклират
                      </div>
                    </div>
                    <div className="ContentScreenContentTrashCanWrap">
                      <div className="ContentScreenContentTrashCan Yellow"></div>
                      <div className="ContentScreenContentTrashCanDescription">
                        Пластмасови и метални отпадъци
                      </div>
                    </div>
                    <div className="ContentScreenContentTrashCanWrap">
                      <div className="ContentScreenContentTrashCan Green"></div>
                      <div className="ContentScreenContentTrashCanDescription">
                        Стъклени отпадъци
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ContentScreenContentTrashCanContainer">
                  <div className="ContentScreenContentTrashCanRow">
                    <div className="ContentScreenContentTrashCanWrap">
                      <div className="ContentScreenContentTrashCan Blue"></div>
                      <div className="ContentScreenContentTrashCanDescription">
                        Хартиени и картонени отпадъци
                      </div>
                    </div>
                    <div className="ContentScreenContentTrashCanWrap">
                      <div className="ContentScreenContentTrashCan Brown"></div>
                      <div className="ContentScreenContentTrashCanDescription">
                        Отпадъци за компостиране
                      </div>
                    </div>
                    <div className="ContentScreenContentTrashCanWrap">
                      <div className="ContentScreenContentTrashCan Red"></div>
                      <div className="ContentScreenContentTrashCanDescription">
                        Електронни отпадъци
                      </div>
                    </div>
                  </div>
                </div>
              </CustomScroll>
            </div>
            <div className="ContentScreenContentButtons">
              <div className="ContentScreenContentButtonsLeft">
                <Link to="game" className="AppButtonDark right">Започни играта</Link>
              </div>
              <div className="ContentScreenContentButtonsRight">
                <a href={CampaignSiteUrl} className="AppButtonLight" target="_blank">Към сайта на кампанията</a>
                <Link to="terms" className="AppButtonLight">Обща информация</Link>
              </div>
            </div>
          </div>
          <div className="ContentScreenContentRight"></div>
        </div>
        <ContentScreenFooter showButtons={false} />
      </div>
    </div>
  );
}

export default ContentScreen;
