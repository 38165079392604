import { memo } from 'react';
import { Link } from "react-router-dom";
import { CampaignSiteUrl } from '../data/Constants';
import CustomScroll from 'react-custom-scroll';

export const ActionModal = memo(function Modal({
  title,
  isSuccess,
  message,
  action,
  actionButtonTitle,
  isLongText
}) {
  return (
    <div className={`GameModal Modal${isSuccess ? " Success" : " Error"}${isLongText ? " LongText" : ""}`}>
      <div className="ModalWrap">
        <div className="ModalContent Full">
          <div className="ModalTitle">{title}</div>
          <div className="ModalMessage">
            {
              isLongText !== undefined && isLongText == true ?
              (
                <CustomScroll heightRelativeToParent="100%">
                  <div dangerouslySetInnerHTML={{__html: message}}></div>
                </CustomScroll>
              ) :
              (
                <div dangerouslySetInnerHTML={{__html: message}}></div>
              )
            }
          </div>
          <div className="ModalButtonsContainer">
            <div className="AppButtonDark right" onClick={() => action()}>{actionButtonTitle}</div>
            <a href={CampaignSiteUrl} className="AppButtonLight MiddleButton" target="_blank">Към сайта на кампанията</a>
            <Link to="/trash-management/terms" className="AppButtonLight">Обща информация</Link>
          </div>
        </div>
      </div>
    </div>
  )
})
