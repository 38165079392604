import CustomScroll from 'react-custom-scroll';
import { Link } from "react-router-dom";
import { ContentScreenFooter } from '../../components/common/ContentScreenFooter';
import { ContentScreenHeader } from '../../components/common/ContentScreenHeader';
import { CampaignSiteUrl, TRASH_MANAGEMENT_GAME_PREFIX } from '../../data';

function IntroScreen() {
  return (
    <div className={`ContentScreen ${TRASH_MANAGEMENT_GAME_PREFIX}`}>
      <div className="ScreenWrap">
        <ContentScreenHeader showHomeButton={true} />
        <div className="ContentScreenContent">
          <div className="ContentScreenContentLeft" style={{ maxWidth: '990px', margin: '0 auto' }}>
            <div className="ContentScreenContentHeader">Игра за управление на отпадъци</div>
            <div className="ContentScreenContentContent">
              <CustomScroll heightRelativeToParent="100%">
                <p>
                Знаете ли какво означава да управляваме отпадъците?
                </p>
                <p>
                Най-лесно е просто да изхвърлиш празната кутия от бисквити, найлоновата торбичка или недоядения сандвич. Но как ще изглежда градът ни, ако всеки постъпва така?
                </p>
                <p>
                За да живеем в чист и красив град, да пазим околната среда и собственото си здраве, трябва да се научим да контролираме образуването на отпадъци. Ето пет лесни стъпки как да направим това:
                </p>

                <p>
                1. Пазаруваме разумно и се стараем да използваме всичко, което сме купили.
                </p>
                <p>
                2. Защо да купуваме найлонова торбичка за покупките, когато може да използваме платнена?
                </p>
                <p>
                3. Отпадъците се превръщат в суровини, от които могат да се произвеждат нови продукти.
                </p>
                <p>
                4. Отпадъците, които не могат да се рециклират се използват за производство на други полезни неща – компост /органичен тор/ или енергия.
                </p>
                <p>
                5. На последно място е изхвърлянето на отпадъците в сметища.
                </p>
                <p>
                Град без отпадъци. Възможно ли е това?
                </p>
                <p>
                Проверете своите знания и научете как да предовратите образуването на отпадъци. Всеки път, когато отговорите правилно може да почистите част от парка. Нека заедно направим града ни по-чист и по-красив.
                </p>
              </CustomScroll>
            </div>
            <div className="ContentScreenContentButtons">
              <div className="ContentScreenContentButtonsLeft">
                <Link to="game" className="AppButtonDark right">Започни играта</Link>
              </div>
              <div className="ContentScreenContentButtonsRight">
                <a href={CampaignSiteUrl} className="AppButtonLight" target="_blank">Към сайта на кампанията</a>
                <Link to="terms" className="AppButtonLight">Обща информация</Link>
              </div>
            </div>
          </div>
        </div>
        <ContentScreenFooter showButtons={false} />
      </div>
    </div>
  );
}

export default IntroScreen;
