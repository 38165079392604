import { memo } from 'react';
import { DragPreviewImage, useDrag } from 'react-dnd';
import { ASSETS_STORAGE_PRFIX_PREFIX } from '../../data/Constants';

export const Trash = memo(function Trash({
  item, level, maxWidth, maxHeight, top, left, image
}) {
  const [{ opacity }, drag, preview] = useDrag(
    () => ({
      type: item.type,
      item: item,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.01 : 1,
      }),
    }),
    [item],
  )
  return (
    <>
      <DragPreviewImage connect={preview} src={`${ASSETS_STORAGE_PRFIX_PREFIX}${image}`} />
      <div className={`Trash`} ref={drag} style={{
        opacity: opacity,
        left: left,
        top: top
      }} data-testid="box">
        <img src={`${ASSETS_STORAGE_PRFIX_PREFIX}${image}`} style={{
          maxWidth: maxWidth,
          maxHeight: maxHeight
        }}/>
      </div>
    </>
  )
})
