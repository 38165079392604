import { memo } from 'react';
import { Link } from "react-router-dom";
import { ASSETS_STORAGE_PRFIX_PREFIX } from '../../data';

export const ContentScreenHeader = memo((props) => {
  return (
    <div className="ContentScreenHeader">
      <div className="Row Grow">
        <div className="AppImage ContentScreenEuLogo"></div>
        <div className="AppImage IntroThinkLogo"></div>
        <div className="AppImage IntroProgramaLogo"></div>
      </div>
      {
        props.showHomeButton ?
        (
          <div className="Row" style={{ justifyContent: 'center', marginTop: '10px' }}>
            <Link to="/" className="ChooseGameButton">
              Избери игра
              <img src={`${ASSETS_STORAGE_PRFIX_PREFIX}assets/images/start/play-console.png`} style={{ width: '20px', marginLeft: '5px', marginBottom: '-3px' }} />
            </Link>
          </div>
        ) :
        (
          <div className="Row" style={{ height: '40px' }}></div>
        )
      }
    </div>
  )
})
