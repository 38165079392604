import { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { TrashGame } from '../../components';
import { FoodTrashes, TrashItemTypes, FoodTrashesQuestions } from '../../data';
import kitchenAudio from '../../assets/audio/Kitchen.mp3';
import useGameMusics from '../../hooks/useGameMusics';
import OrderingGame from '../../components/ordering-game/OrderingGame';
import QuestionsGame from '../../components/questions-game/QuestionsGame';

function TrashGameScreen(props) {
  const navigate = useNavigate();
  const audioMuted = props.audioMuted;
  const setAudioMuted = props.setAudioMuted;
  const gameMusics = useGameMusics(kitchenAudio, setAudioMuted);
  const [currentGame, setCurrentGame] = useState("ordering");

  return (
    <div className={`GameCanvasScreen GameCanvasScreen-FoodTrashes`}>
      <div className="GameCanvasScreenHeader">
        <div className="GameCanvasScreenHeaderRow">
          <div className="GameCanvasScreenHeaderLeftButtons">
            <Link to="/food-trashes" className="GoToHomeButton"></Link>
          </div>
          {
            currentGame === "trashes" || currentGame === "ordering" ?
            (
              <div className="GameCanvasScreenPointsContainer">
                <div className="GameCanvasScreenExplain">
                  {
                    currentGame === "ordering" ?
                    (<>Животът на ягодката:<br/>подредете в правилен ред етапите от пътя на ягодката от фермата до дома.</>)
                    :
                    (<>Поставете храната на правилното място за съхранение - шкаф, хладилник или фризер</>)
                  }
                </div>
              </div>
            ) : null
          }
          <div className="GameCanvasScreenHeaderRightButtons">
            <div className={`AudioButton ${audioMuted ? 'muted' : 'playing'}`} onClick={
              () => {
                gameMusics.toggleAudio(audioMuted)
              }
            }></div>
          </div>
        </div>
      </div>

      {
        currentGame == "ordering" ?
        (
          <OrderingGame
            gameWrapperClass="OrderingGame"
            orderingSteps={["Опаковане", "Доставка", "Хладилник", "Бране", "Магазин", "Отглеждане"]}
            rightOrdering={[6, 4, 1, 2, 5, 3]}
            onComplete={() => { setCurrentGame("trashes") }}
          ></OrderingGame>
        ) : null
      }

      {
        currentGame == "trashes" ?
        (
          <TrashGame
            dustbins={
              [
                TrashItemTypes.CLOSET,
                TrashItemTypes.FRIDGE,
                TrashItemTypes.FREEZER
              ]
            }
            gameWrapperClass="FoodTrashesGameContainer"
            gameMusics={gameMusics}
            levelsCount={1}
            pointsPerItem={10}
            setPublicState={(state) => { }}
            successPercentage={75}
            trashes={FoodTrashes}
            trashesBasePath="assets/images/food-trashes-game/trashes"
            isFinalGame={false}
            onComplete={() => {
              setCurrentGame("questions")
            }}
          ></TrashGame>
        ) : null
      }

      {
        currentGame == "questions" ?
        (
          <QuestionsGame
            gameMusics={gameMusics}
            questionHideInterval={1000}
            hasManualSelection={false}
            questions={FoodTrashesQuestions}
            successTitle="Поздравления! Ти Успя."
            errorTitle="Опитай отново!"
            successMessage="<p style='font-size: 14px; margin-top: 0;'>За много хора  разхищението на храна се е превърнало в навик. Когато прахосваме храна, ние пилеем труда, усилията, инвестициите и ценните ресурси, като вода, семена, фураж и т.н. Всъщност в световен мащаб всеки ден се  прахосват тонове годна за консумация храна, а в същото време милиони хора гладуват. Ако намаляваш хранителните си отпадъците, ставаш съпричастен към тези хора по света, за които  храната не е даденост. Ето няколко лесни за изпълнение действия:<p>
<p style='font-size: 14px;'>
1. Храни се по-здравословно<br/>
2. Купувай само това, от което се нуждаеш<br/>
3. Съхранявай храната интелигентно<br/>
4. Чети правилно етикетите на храните - има голяма разлика между датите „най-добър до“ и „използван до“. Понякога храната все още е безопасна за консумация след датата „най-добър до“, докато датата „използван до“ ти казва кога вече не е безопасна за консумация. Проверявай етикетите на храните за нездравословни съставки като трансмазнини, консерванти и избягвай храни с добавена захар или сол.<br/>
5. Използвай хранителните си остатъци – замразявай остатъците или ги използвай за съставка в друго хранене. Компостирай ги. По този начин връщаш хранителни вещества обратно  в почвата и намаляваш въглеродния си отпечатък.
</p>"
            errorMessage="Направи 3 грешки, опитай пак!"
            actionButtonTitle="Започни отначало"
            onComplete={() => {
              navigate(-1);
            }}
          />
        ) : null
      }
    </div>
  );
}

export default TrashGameScreen;
