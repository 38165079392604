import { useEffect, useState } from "react";
import successAudio from '../assets/audio/success.mp3';
import errorAudio from '../assets/audio/error.mp3';
import winAudio from '../assets/audio/win.mp3';
import { useCallback } from "react";
import useAudio from "./useAudio";

const useGameMusics = (backgroundAudio, setAudioMuted) => {
  const [backgroundPlayer] = useState(useAudio(backgroundAudio, true, 0.4));
  const [successPlayer] = useState(useAudio(successAudio, false, 1));
  const [errorPlayer] = useState(useAudio(errorAudio, false, 1));
  const [winPlayer] = useState(useAudio(winAudio, false, 1));

  useEffect(() => {
    document.body.addEventListener('click', playBackgroundAudio );

    return function cleanup() {
      backgroundPlayer.stop();
      document.body.removeEventListener('click', playBackgroundAudio );
    }
  },[]);

  const playBackgroundAudio = useCallback(() => {
    backgroundPlayer.play();
  }, []);

  const playSuccess = useCallback(() => {
    successPlayer.play();
  }, []);

  const playError = useCallback(() => {
    errorPlayer.play();
  }, []);

  const playWin = useCallback(() => {
    winPlayer.play();
  }, []);

  const toggleAudio = useCallback((audioMuted) => {
    if(audioMuted) {
      backgroundPlayer.unMute();
      successPlayer.unMute();
      errorPlayer.unMute();
      winPlayer.unMute();
    } else {
      backgroundPlayer.mute();
      successPlayer.mute();
      errorPlayer.mute();
      winPlayer.mute();
    }
    setAudioMuted(!audioMuted);
  })

  return {
    playBackgroundAudio,
    playSuccess,
    playError,
    playWin,
    toggleAudio
  };
};

export default useGameMusics;
